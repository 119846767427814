import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I knew it was coming, I knew he would leave,`}<br parentName="p"></br>{`
`}{`I tried to prepare but it wasn't enough.`}<br parentName="p"></br>{`
`}{`Even from the start, I was too naive,`}<br parentName="p"></br>{`
`}{`I should've known it was always a bluff.`}</p>
    <p>{`Now I walk alone, fully in the dark,`}<br parentName="p"></br>{`
`}{`Listening to the crinkling leaves fall down.`}<br parentName="p"></br>{`
`}{`What to do? I have nothing to embark.`}<br parentName="p"></br>{`
`}{`The lines have blurred so I should just skip town.`}</p>
    <p>{`But no! I will not let this change everything,`}<br parentName="p"></br>{`
`}{`I haven’t lost yet, no need for an adieu.`}<br parentName="p"></br>{`
`}{`Maybe my business will even upswing,`}<br parentName="p"></br>{`
`}{`By all means, I can start completely anew.`}</p>
    <p>{`Even though I was betrayed by his greed,`}<br parentName="p"></br>{`
`}{`It won't affect me since there is no need.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      